import { gql } from '@apollo/client';
import { AddressFragment } from 'fhir/general/Address';
import { HumanNameFragment } from 'fhir/general/HumanName';
import { CodeableConceptFragment } from 'fhir/general/CodeableConcept';
import { DocumentReferenceFragment } from 'styleguide/form/Document';
import { IdentifierFragment } from 'fhir/general/Identifier';

export const CreateEncounterMutation = gql`
  mutation createEncounter(
    $encounter: EncounterInput!
    $authorization: ReferenceInput
    $routeEnabled: Boolean
    $routeId: String
  ) {
    createEncounter(
      encounter: $encounter
      authorization: $authorization
      routeEnabled: $routeEnabled
      routeId: $routeId
    ) {
      id
    }
  }
`;

export const UpdateEncounterMutation = gql`
  mutation updateEncounter(
    $id: String!
    $encounter: EncounterInput!
    $authorization: ReferenceInput
    $isHandleAuthorization: Boolean
    $routeId: String
    $routeEnabled: Boolean
  ) {
    updateEncounter(
      id: $id
      encounter: $encounter
      authorization: $authorization
      isHandleAuthorization: $isHandleAuthorization
      routeEnabled: $routeEnabled
      routeId: $routeId
    ) {
      id
    }
  }
`;

export const CancelEncounterMutation = gql`
  mutation cancelEncounter($encounterId: String!, $cancelReason: CodeInput) {
    cancelEncounter(encounterId: $encounterId, cancelReason: $cancelReason) {
      id
    }
  }
`;

export const CancelEncounterAndResetRouteStopMutation = gql`
  mutation cancelEncounterAndResetRouteStop(
    $encounterId: String!
    $cancelReason: CodeInput
    $resetStopActualParams: ResetStopActualParams!
  ) {
    cancelEncounter(encounterId: $encounterId, cancelReason: $cancelReason) {
      id
    }

    resetStopActual(input: $resetStopActualParams) {
      id
    }
  }
`;

export const StartEncounterMutation = gql`
  mutation startEncounter(
    $encounterId: String!
    $provenance: CheckInProvenanceInput!
  ) {
    startEncounter(encounterId: $encounterId, provenance: $provenance) {
      appointmentId
      appointmentStartDate
    }
  }
`;

export const startEncounterAndRouteCheckinMutation = gql`
  mutation startEncounterAndRouteCheckin(
    $encounterId: String!
    $provenance: CheckInProvenanceInput!
    $routeCheckinParams: StartAppointmentParams!
  ) {
    startEncounter(encounterId: $encounterId, provenance: $provenance) {
      appointmentId
      appointmentStartDate
    }

    startAppointment(input: $routeCheckinParams) {
      id
    }
  }
`;

export const EncounterExtensionFragment = gql`
  fragment Extension on Extension {
    url
    valuePositiveInt
    valueBoolean
    valueDateTime
    valueReference {
      reference
    }
    valueString
    extension {
      url
      valuePositiveInt
      valueString
      valueDateTime
      valueReference {
        type
        reference
        display
      }
      __typename
    }
    __typename
  }
`;

export const EncounterExtensionFragmentUrl = gql`
  fragment Extension on Extension {
    url
    valuePositiveInt
    valueBoolean
    valueDateTime
    valueReference {
      reference
    }
    valueString
    extension {
      url
      valuePositiveInt
      valueString
      valueDateTime
      valueReference {
        type
        reference
        display
      }
      __typename
    }
    __typename
  }
`;

export const EncounterStateFragment = gql`
  fragment EncounterState on EncounterState {
    permission {
      allowESign
      allowAmend
      allowCheckout
    }
    status
  }
`;

export const EncounterListQuery = gql`
  query EncounterList(
    $episodeOfCareId: String
    $patientId: String
    $status: String
    $stateStatus: String
    $period_start: String
    $first: Int
    $after: String
    $appointment: String
    $authorization: String
    $type: String
    $typeNot: String
    $deliveryType: String
    $tmStatuses: [String]
    $practitionerIds: [String]
    $periodEnd: String
    $includeSP: Boolean
    $sort: String
  ) {
    EncounterList(
      episodeOfCareId: $episodeOfCareId
      patientId: $patientId
      status: $status
      stateStatus: $stateStatus
      period_start: $period_start
      first: $first
      after: $after
      appointment: $appointment
      authorization: $authorization
      type: $type
      typeNot: $typeNot
      deliveryType: $deliveryType
      tmStatuses: $tmStatuses
      practitionerIds: $practitionerIds
      periodEnd: $periodEnd
      includeSP: $includeSP
      sort: $sort
    ) {
      edges {
        cursor
        node {
          id
          status
          resourceType
          identifier {
            ...Identifier
          }
          stateText {
            text
          }
          class {
            code
            system
            display
          }
          extension {
            ... on Extension {
              ...Extension
            }
            ... on ExtensionWithReferenceResource {
              url
              valueReference {
                reference
                resource
              }
            }
          }
          contained {
            id
            resourceType
            type {
              ...CodeableConcept
            }
          }
          type {
            ...CodeableConcept
          }
          period {
            start
            end
          }
          location {
            location {
              reference
            }
          }
          participant {
            type {
              ...CodeableConcept
            }
            individual {
              reference
              resource {
                id
                name {
                  family
                  given
                }
              }
            }
          }
          episodeOfCare {
            reference
          }
          reviews {
            status
            type
            text
          }
          subject {
            reference
            resource {
              name {
                ...HumanName
              }
              birthDate
              gender
              address {
                extension {
                  url
                  valueString
                  valueBoolean
                }
              }
            }
            __typename
          }
          partOf {
            reference
          }
          appointment {
            reference
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${CodeableConceptFragment}
  ${IdentifierFragment}
  ${HumanNameFragment}
  ${EncounterExtensionFragment}
`;

export const ParentEncounterListQuery = gql`
  query EncounterList($episodeOfCareId: String, $first: Int, $type: String) {
    EncounterList(
      episodeOfCareId: $episodeOfCareId
      first: $first
      type: $type
    ) {
      edges {
        cursor
        node {
          id
          status
          resourceType
          class {
            system
            code
            display
          }
          type {
            ...CodeableConcept
          }
          episodeOfCare {
            reference
          }
          subject {
            reference
            resource {
              address {
                extension {
                  url
                  valueString
                  valueBoolean
                }
              }
            }
            __typename
          }
          partOf {
            reference
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
      }
    }
  }
  ${CodeableConceptFragment}
`;

export const EncounterState = gql`
  query EncounterState($encounterId: String!) {
    EncounterState(encounterId: $encounterId) {
      ...EncounterState
    }
  }
  ${EncounterStateFragment}
`;

export const canGenenerateCCD = gql`
  query canGenenerateCCD($episodeOfCareId: String!) {
    canGenenerateCCD(episodeOfCareId: $episodeOfCareId) {
      valid
    }
  }
`;

export const EncounterFragment = gql`
  fragment Encounter on Encounter {
    id
    status
    resourceType
    identifier {
      ...Identifier
    }
    extension {
      ... on Extension {
        ...Extension
      }
      ... on ExtensionWithReferenceResource {
        url
        valueReference {
          reference
          resource
          type
          display
        }
      }
    }
    stateText {
      status
      text
    }
    class {
      code
      system
      display
    }
    contained {
      id
      resourceType
      type {
        ...CodeableConcept
      }
      address {
        ...Address
      }
      position {
        latitude
        longitude
        altitude
      }
    }
    type {
      ...CodeableConcept
    }
    period {
      start
      end
    }
    location {
      location {
        reference
      }
    }
    participant {
      type {
        ...CodeableConcept
      }
      individual {
        reference
        resource {
          id
          name {
            family
            given
          }
          extension {
            url
            valueString
            valueBoolean
            valueCodeableConcept {
              ...CodeableConcept
            }
            extension {
              url
              valueReference {
                reference
              }
              valueString
              valueBoolean
              valueCodeableConcept {
                ...CodeableConcept
              }
            }
          }
        }
      }
      supervisingPhysician {
        name {
          given
          family
        }
      }
    }
    episodeOfCare {
      reference
    }
    subject {
      reference
      resource {
        address {
          extension {
            url
            valueString
            valueBoolean
          }
        }
      }
      __typename
    }
    partOf {
      reference
    }
    appointment {
      reference
    }
    appointmentStartDate
    reviews {
      status
      type
      text
    }
  }
  ${CodeableConceptFragment}
  ${IdentifierFragment}
  ${AddressFragment}
  ${EncounterExtensionFragmentUrl}
  ${CodeableConceptFragment}
`;

export const GetLatestEncounterByPractitioner = gql`
  query LatestEncounterForPractitioner($practitionerId: String!) {
    LatestEncounterForPractitioner(practitionerId: $practitionerId) {
      id
      status
      resourceType
      identifier {
        ...Identifier
      }
      extension {
        ... on Extension {
          ...Extension
        }
        ... on ExtensionWithReferenceResource {
          url
          valueReference {
            reference
            resource
          }
        }
      }
      stateText {
        status
        text
      }
      class {
        code
        system
        display
      }
      contained {
        id
        resourceType
        type {
          ...CodeableConcept
        }
        address {
          ...Address
        }
        position {
          latitude
          longitude
          altitude
        }
      }
      type {
        ...CodeableConcept
      }
      period {
        start
        end
      }
      location {
        location {
          reference
        }
      }
      participant {
        type {
          ...CodeableConcept
        }
        individual {
          reference
          resource {
            id
            name {
              ...HumanName
            }
          }
        }
      }
      episodeOfCare {
        reference
      }
      subject {
        reference
        resource {
          name {
            ...HumanName
          }
          birthDate
          gender
          address {
            extension {
              url
              valueString
              valueBoolean
            }
          }
        }
      }
      partOf {
        reference
      }
      appointment {
        reference
      }
      reviews {
        status
        type
        text
      }
    }
  }
  ${CodeableConceptFragment}
  ${IdentifierFragment}
  ${HumanNameFragment}
  ${AddressFragment}
  ${EncounterExtensionFragment}
`;

export const EncounterQuery = gql`
  query Encounter($id: String!) {
    Encounter(id: $id) {
      ...Encounter
    }
  }
  ${EncounterFragment}
`;

export const GetActiveEncounterByAppointmentIdQuery = gql`
  query GetActiveEncounterByAppointmentId($id: String!) {
    GetActiveEncounterByAppointmentId(id: $id) {
      id
    }
  }
`;

export const EncounterWithStatus = gql`
  query Encounter($id: String!) {
    Encounter(id: $id) {
      ...Encounter
      telemedicineEncounter {
        status
      }
    }
    EncounterState(encounterId: $id) {
      ...EncounterState
    }
  }
  ${EncounterFragment}
  ${EncounterStateFragment}
`;

export const EncounterSummaryQuery = gql`
  query Encounter($encounterId: String!) {
    Encounter(id: $encounterId) {
      text
    }
  }
`;

export const QuestionnaireList = gql`
  query getQuestionnaireListByPlanDefinition(
    $id: String!
    $encounteredType: String!
  ) {
    getQuestionnaireListByPlanDefinition(
      id: $id
      encounteredType: $encounteredType
    ) {
      title
      code {
        coding {
          code
          display
          system
        }
      }
      requiredBehavior
      definitionCanonical
      selectionBehavior
      cardinalityBehavior
      timingTiming {
        extension {
          valueInteger
          url
        }
      }
    }
  }
`;

export const QuestionnaireListByEncounterType = gql`
  query getQuestionnaireListByEncounterType(
    $url: String!
    $encounteredType: String!
  ) {
    getQuestionnaireListByEncounterType(
      url: $url
      encounteredType: $encounteredType
    ) {
      title
      code {
        coding {
          code
          display
          system
        }
      }
      requiredBehavior
      definitionCanonical
      selectionBehavior
      cardinalityBehavior
      timingTiming {
        extension {
          valueInteger
          url
        }
      }
    }
  }
`;
export const UpdateEncounterQuestionnaireList = gql`
  mutation updateEncounterQuestionnaireList(
    $updateQuestionnaire: UpdateQuestionnaireListInput!
  ) {
    updateEncounterQuestionnaireList(
      updateQuestionnaire: $updateQuestionnaire
    ) {
      id
      title
      encounter {
        reference
      }
      entry {
        item {
          display
          reference
        }
      }
    }
  }
`;

export const GetUpdatedEncounterQuestionnaireList = gql`
  query getQuestionnaireListByEncounter(
    $encounterId: String
    $encounterType: String!
    $appointmentDate: String
    $admissionId: String
  ) {
    getQuestionnaireListByEncounter(
      encounterId: $encounterId
      encounterType: $encounterType
      appointmentDate: $appointmentDate
      admissionId: $admissionId
    ) {
      id
      title
    }
    QuestionnaireResponseListForClinical(encounterId: $encounterId) {
      id
      encounter {
        reference
      }
      questionnaire
    }
  }
`;

export const GetQuestionnaireListByEncounterQuery = gql`
  query getQuestionnaireListByEncounter(
    $encounterId: String!
    $encounterType: String!
  ) {
    getQuestionnaireListByEncounter(
      encounterId: $encounterId
      encounterType: $encounterType
    ) {
      id
      title
      isRequired
    }
  }
`;

export const GetEncounterQuestionnaireResponseListQuery = gql`
  query QuestionnaireResponseListForClinical($encounterId: String!) {
    QuestionnaireResponseListForClinical(encounterId: $encounterId) {
      id
      encounter {
        reference
      }
      questionnaire
    }
  }
`;

export const EncounterQuestionnaireList = gql`
  query GetPlanDefinitionById($id: String!, $encounterId: String) {
    GetPlanDefinitionById(id: $id) {
      id
      resourceType
      action {
        title
        code {
          ...CodeableConcept
        }
        definitionCanonical
      }
    }
    QuestionnaireResponseListForClinical(encounterId: $encounterId) {
      id
      encounter {
        reference
      }
      questionnaire
    }
  }
  ${CodeableConceptFragment}
`;

export const ESignEncounter = gql`
  mutation esignEncounter($encounterId: String!) {
    esignEncounter(encounterId: $encounterId) {
      ... on ResponseValidationError {
        missingAnswers {
          id
          title
          conditions {
            id
            name
            linkIds
          }
        }
      }
      ... on EncounterReference {
        resource {
          id
          status
        }
      }
    }
  }
`;

export const AmendEncounter = gql`
  mutation amendEncounter($encounterId: String!, $reason: String) {
    amendEncounter(encounterId: $encounterId, reason: $reason) {
      resource {
        diagnosis {
          rank
        }
        status
        id
        episodeOfCare {
          type
        }
      }
      display
      id
      reference
    }
  }
`;

export const GetMissingAnswers = gql`
  query MissingAnswers($encounterId: String!) {
    MissingAnswers(encounterId: $encounterId) {
      missingAnswers {
        id
        title
        conditions {
          id
          name
          linkIds
        }
      }
    }
  }
`;

export const createProvenance = gql`
  mutation createProvenance(
    $provenance: CheckInProvenanceInput!
    $teleMedicineType: String
  ) {
    createProvenance(
      provenance: $provenance
      teleMedicineType: $teleMedicineType
    ) {
      id
    }
  }
`;

export const GetProvenanceQuery = gql`
  query GetProvenance($patientId: String!, $encounterId: String!) {
    GetProvenance(patientId: $patientId, encounterId: $encounterId) {
      id
      contained {
        address {
          ...Address
        }
        position {
          latitude
          longitude
          altitude
        }
      }
      resourceType
      recorded
      reason {
        ...CodeableConcept
      }
      agent {
        who {
          identifier {
            value
          }
          display
        }
      }
      target {
        reference
      }
      activity {
        ...CodeableConcept
      }
      entity {
        what {
          reference
          resource {
            id
            status
            participant {
              type {
                ...CodeableConcept
              }
              individual {
                reference
                resource {
                  id
                  name {
                    ...HumanName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${HumanNameFragment}
  ${CodeableConceptFragment}
  ${AddressFragment}
`;

export const LastCapturedQuery = gql`
  query Encounter($id: String!) {
    Encounter(id: $id) {
      id
      period {
        start
        end
      }
      status
      episodeOfCare {
        reference
      }
    }
  }
`;

export const GetLatestEncounterWithQuestionnaireResponse = gql`
  query GetLatestEncounterWithQuestionnaireResponse(
    $questionnaireId: String!
    $episodeOfCareId: String!
    $patientId: String!
  ) {
    GetLatestEncounterWithQuestionnaireResponse(
      questionnaireId: $questionnaireId
      episodeOfCareId: $episodeOfCareId
      patientId: $patientId
    ) {
      questionnaire
      authored
      encounter {
        reference
      }
      subject {
        reference
      }
      item {
        linkId
        answer {
          valueString
          valueDateTime
          valueInteger
          valueCoding {
            code
            display
            system
          }
          valueReference {
            reference
          }
        }
        item {
          linkId
          answer {
            valueString
            valueDateTime
            valueInteger
            valueCoding {
              code
              display
              system
            }
            valueReference {
              reference
            }
          }
          item {
            linkId
            answer {
              valueString
              valueDateTime
              valueInteger
              valueCoding {
                code
                display
                system
              }
              valueReference {
                reference
              }
            }
            item {
              linkId
              answer {
                valueString
                valueDateTime
                valueInteger
                valueCoding {
                  code
                  display
                  system
                }
                valueReference {
                  reference
                }
              }
              item {
                linkId
                answer {
                  valueString
                  valueDateTime
                  valueInteger
                  valueCoding {
                    code
                    display
                    system
                  }
                  valueReference {
                    reference
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getLocationQuery = gql`
  query locationDataSet($latlong: LocationServiceInput) {
    LocationDataSet(latlong: $latlong) {
      address {
        line
        city
        postalCode
        state
      }
    }
  }
`;

export const ccdEncounterNarrativeMutation = gql`
  mutation generateEncounterNarrativePDF($encounterId: String!) {
    generateEncounterNarrativePDF(encounterId: $encounterId) {
      ...DocumentReference
    }
  }
  ${DocumentReferenceFragment}
`;

export const EncounterVersionHistory = gql`
  query Encounter($id: String!) {
    Encounter(id: $id) {
      versions {
        compositionId
        status
        clinician {
          name
          npi
          lastUpdatedOn
          lastUpdatedOnDateTime
        }
        onsiteProvider {
          name
          npi
          lastUpdatedOn
          lastUpdatedOnDateTime
        }
        amendClinician {
          name
          npi
          lastUpdatedOn
        }
        reasons
      }
    }
  }
`;

export const QuestionnaireResponseItemFragment = gql`
  fragment QuestionnaireResponseItem on QuestionnaireResponseItem {
    linkId
    answer {
      valueAttachment {
        id
        contentType
        title
      }
      valueDate
      valueDateTime
      valueInteger
      valueString
      valueCoding {
        system
        code
        display
      }
      valueQuantity {
        value
      }
    }
  }
`;

export const GetLatestAssessmentResponses = gql`
  query LatestAssessmentResponses($episodeOfCareId: String!) {
    LatestAssessmentResponses(episodeOfCareId: $episodeOfCareId) {
      resourceType
      id
      questionnaire
      questionnaireResource {
        title
      }
      partOf {
        reference
        display
        type
      }
      subject {
        reference
        display
        type
      }
      encounter {
        reference
        display
        resource {
          id
          type {
            ...CodeableConcept
          }
          period {
            start
            end
          }
          latestVersion {
            compositionId
          }
        }
      }
      author {
        reference
        display
        type
      }
      source {
        reference
        display
        type
      }
      authored
      text
      item {
        ...QuestionnaireResponseItem
        item {
          ...QuestionnaireResponseItem
          item {
            ...QuestionnaireResponseItem
            item {
              ...QuestionnaireResponseItem
            }
          }
        }
      }
    }
  }
  ${QuestionnaireResponseItemFragment}
  ${CodeableConceptFragment}
`;
export const GetEncounterVersion = gql`
  query GetEncounterVersion($compositionId: String!) {
    GetEncounterVersion(compositionId: $compositionId)
  }
`;

export const discardQuestionnaireResponse = gql`
  mutation discardQuestionnaireResponse($questionnaireResponseId: String!) {
    discardQuestionnaireResponse(
      questionnaireResponseId: $questionnaireResponseId
    ) {
      deleted
    }
  }
`;

export const getQuestionnaireResponsesByLatestCompositionStatus = gql`
  query QuestionnaireResponsesByLatestCompositionStatus(
    $encounterId: String!
    $status: String!
  ) {
    QuestionnaireResponsesByLatestCompositionStatus(
      encounterId: $encounterId
      status: $status
    ) {
      questionnaire
    }
  }
`;

export const onEncounterCreatedSubscription = gql`
  subscription onEncounterCreated(
    $patientStates: [String]
    $telemedicineStatuses: [String]
    $telemedicineClinicianId: String
  ) {
    onEncounterCreated(
      patientStates: $patientStates
      telemedicineStatuses: $telemedicineStatuses
      telemedicineClinicianId: $telemedicineClinicianId
    ) {
      resource
    }
  }
`;

export const onEncounterUpdatedSubscription = gql`
  subscription onEncounterUpdated(
    $patientStates: [String]
    $telemedicineStatuses: [String]
    $telemedicineClinicianId: String
  ) {
    onEncounterUpdated(
      patientStates: $patientStates
      telemedicineStatuses: $telemedicineStatuses
      telemedicineClinicianId: $telemedicineClinicianId
    ) {
      resource
    }
  }
`;

export const getPreviousAndCurrentEncounter = gql`
  query GetPreviousAndCurrentEncounter($encounterId: String!) {
    GetPreviousAndCurrentEncounter(encounterId: $encounterId) {
      previousEncounter {
        id
      }
    }
  }
`;

export const PerformTMAutoCheckout = gql`
  mutation PerformTMAutoCheckout($encounterId: String!) {
    PerformTMAutoCheckout(encounterId: $encounterId)
  }
`;

export const onResourceNarrativeUpdatedSubscription = gql`
  subscription onResourceNarrativeUpdated($encounterId: String) {
    onResourceNarrativeUpdated(encounterId: $encounterId) {
      resource
    }
  }
`;
export const GetAdditionalActionsStatus = gql`
  query GetAdditionalActionsStatus($patientId: String!) {
    GetAdditionalActionsStatus(patientId: $patientId) {
      orderDetails {
        draft
        active
        count
      }
      problemDetails {
        draft
        active
        count
      }
      patientDetails {
        status
        dischargedOn
      }
    }
  }
`;
export const GetEncounterFormStatus = gql`
  query GetEncounterFormStatus(
    $episodeOfCareId: String!
    $encounterId: String!
  ) {
    GetEncounterFormStatus(
      episodeOfCareId: $episodeOfCareId
      encounterId: $encounterId
    ) {
      barriers {
        lastUpdated
      }
    }
  }
`;

export const latestUpdatedFormsDateQuery = gql`
  query getLatestUpdatedFormsDate($encounterId: String!) {
    getLatestUpdatedFormsDate(encounterId: $encounterId)
  }
`;

export const checkMedicalHistoryFilled = gql`
  query checkMedicalHistoryFilled($admissionId: String!) {
    checkMedicalHistoryFilled(admissionId: $admissionId)
  }
`;

export const CreateEncounterFormsMutation = gql`
  mutation createEncounterForms(
    $encounterId: String!
    $encounterType: String!
  ) {
    createEncounterForms(
      encounterId: $encounterId
      encounterType: $encounterType
    )
  }
`;

export const onEncounterWoundUpdatedSubscription = gql`
  subscription onWoundUpdated($woundId: String) {
    onWoundUpdated(woundId: $woundId) {
      message
    }
  }
`;
