export const weekDays = [
  { 0: 'S' },
  { 1: 'M' },
  { 2: 'T' },
  { 3: 'W' },
  { 4: 'T' },
  { 5: 'F' },
  { 6: 'S' },
];
export const DayIndexes = [0, 1, 2, 3, 4, 5, 6];
export const weekDay = 'WEEK_DAY';
export const SLOT_SEQUENCE_EXT = 'SLOT_SEQUENCE';
export const OPERATION_TYPE_DELETE = 'DELETE';
export const HIDE_SCHEDULE_DEFAULT_INFO_EXT = 'HIDE_SCHEDULE_DEFAULT_INFO';
export const numberOfVisits = 'NUMBER_OF_VISITS';
export const isDayAvailable = 'IS_DAY_AVAILABLE';
export const startOfCare = 'soc-patient';
export const APPOINTMENT_TYPE_SOC_KIND_CODES = [
  'soc-patient',
  'soc-evaluation',
  're-admit',
];
export const toggleTextEnabled = 'Enabled';
export const toggleTextDisabled = 'Disabled';
export const EXTENSION_URL_CLINICIAN_ROUTE_TOGGLE = 'CLINICIAN_ROUTE_TOGGLE';
export const ERROR_ACCOUNT_NOT_LINKED =
  'Your user account is not linked to the clinician account. Please contact the support team for any changes required.';
export const EXTENSION_URL_CLINICIAN_ROUTE_TOGGLE_DATE =
  'CLINICIAN_ROUTE_TOGGLE_DATE';

export const STATE = 'State';
export const PRIMARY_MARKET = 'Primary Market';
export const ADITIONAL_MARKET = 'Additional Market';

export const MARKETS_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/markets';
export const MARKET_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/market';
export const ADDITIONAL_MARKETS_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/add-markets';
export const ADDITIONAL_MARKET_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/add-market';
export const STATES_EXTENSION_URL =
  'http://woundtech.net/fhir/extensions/states';
export const STATE_EXTENSION_URL = 'http://woundtech.net/fhir/extensions/state';
export const EXTENSION_URL_CLINICIAN_ACUITY_LEVEL_TOGGLE =
  'CLINICIAN_ACUITY_LEVEL_TOGGLE';
export const clinicianLabelGoogleMap = 'Start/End';
export const APPOINTMENT_TYPE_CODES = [
  'soc-patient',
  'follow-up',
  'soc-evaluation',
  're-admit',
];
