import { LIST_TYPE } from 'bulk-operations/constants';
import { SECONDARYICD10_CODE, SECONDARYICD10_TEXT } from 'common/constants';
const refinableCodeGen = count =>
  Array.apply(null, Array(count)).map(function(_, i) {
    return `${SECONDARYICD10_CODE}${i + 1}`;
  });
const refinableTextGen = count =>
  Array.apply(null, Array(count)).map(function(_, i) {
    return `${SECONDARYICD10_TEXT}${i + 1}`;
  });

export const extractCodesText = entity => {
  let secondaryOptionsArray: any = [];
  const refinableText = refinableTextGen(4);
  refinableCodeGen(4).forEach((item, i) => {
    if (entity[item]) {
      secondaryOptionsArray.push({
        code: i,
        icd10CmCode: entity[item],
        title: entity[refinableText[i]],
      });
    }
  });
  return secondaryOptionsArray;
};
export const hasPcpFax = (listType, item) => {
  switch (listType) {
    case LIST_TYPE.madeEncounters:
      return encountersWithPcpFax(item);
    case LIST_TYPE.missedAppointments:
      return pcpFaxExistsAppointment(item);
    default:
      return true;
  }
};

const encountersWithPcpFax = encounter => {
  if (!encounter) return false;
  const patientResource: any = encounter?.subject?.resource;
  return patientWithPcpFax(patientResource);
};
const pcpFaxExistsAppointment = appointment => {
  if (!appointment) return false;
  const patientResource = appointment?.participant
    ?.filter(
      participant => participant?.actor?.resource?.resourceType === 'Patient'
    )
    ?.map(patient => patient?.actor?.resource)?.[0];
  return patientWithPcpFax(patientResource);
};
const patientWithPcpFax = patientResource => {
  let generalPractitioner: any = {};
  if (!patientResource || Object.keys(patientResource).length === 0) {
    return false;
  }
  if (patientResource && patientResource.generalPractitioner) {
    generalPractitioner = patientResource?.generalPractitioner[0];
    if (!generalPractitioner?.reference) return false;
  }
  if (!generalPractitioner || Object.keys(generalPractitioner).length === 0) {
    return false;
  }
  let telecomDetails =
    generalPractitioner?.resource?.practitioner?.resource?.telecom;
  if (!telecomDetails) return false;
  let faxCondtion = telecomDetails.some(element => {
    return element.system === 'fax' && element.value !== null;
  });
  return !!faxCondtion;
};

export const extractSelectedAppointments = appointmentArray => {
  return appointmentArray?.filter(appointment => {
    return appointment?.isChecked === true;
  });
};
export const extractSelectedAppointmentIds = appointmentArray => {
  return appointmentArray
    ?.filter(appointment => {
      return appointment?.isChecked === true;
    })
    ?.map(item => item?.id);
};

export const getNoPcpFax = appointments => {
  return appointments
    ?.map(appointment => appointment?.pcpFax)
    ?.some(item => item);
};

export const sortArray = (sortArray, property) => {
  sortArray?.item?.sort(function(a, b) {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
  return sortArray;
};

export const sortChildArray = (sortArray, property, sortProp, index) => {
  sortArray?.item?.sort(function(a, b) {
    if (
      a[sortProp]?.[0]?.[property].toLowerCase() <
      b[sortProp]?.[0]?.[property].toLowerCase()
    ) {
      return -1;
    }
    if (
      a[sortProp]?.[0]?.[property].toLowerCase() >
      b[sortProp]?.[0]?.[property].toLowerCase()
    ) {
      return 1;
    }
    return 0;
  });
  return sortArray;
};

export const sortCodeSystemList = (sortArray, property) => {
  const sortResult = (a, b) => {
    if (a[property].toLowerCase() < b[property].toLowerCase()) {
      return -1;
    }
    if (a[property].toLowerCase() > b[property].toLowerCase()) {
      return 1;
    }
    return 0;
  };
  if (sortArray?.CodeSystem?.concept.length > 0) {
    sortArray?.CodeSystem?.concept.sort(sortResult);
    sortArray?.CodeSystem?.concept.forEach((item, index) => {
      if (item?.concept !== null) item?.concept.sort(sortResult);
    });
  }
  return sortArray;
};

export const checkRoutePermission = (
  permission: string | string[],
  isAuthorized: (value) => boolean
) => {
  if (Array.isArray(permission)) {
    return permission?.some(item => isAuthorized(item));
  } else {
    return isAuthorized(permission);
  }
};

export const getDataTestIdProps = (dataTestId: string) => {
  return dataTestId ? { 'data-testid': dataTestId } : {};
};
