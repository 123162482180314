import { PATIENT_CHECKED_IN_URL } from 'encounter/constants';

type Collection = {
  [id: string]: any;
};

export const isCollectionEmpty = (data: Collection) => {
  return Object.keys(data).filter(key => !!data[key]).length < 1;
};

export const areCollectionsEqual = (
  primary: Collection,
  secondary: Collection
) => {
  return Object.keys(primary).every(key => {
    return primary[key] === secondary[key];
  });
};

export function upperCase(str: string) {
  if (!str) return str;

  return str.toUpperCase();
}

export function isArrayEmpty(data) {
  if (typeof data !== 'undefined') {
    return data.every(item => item === '');
  }
  return true;
}

export function getProblemStatus(data) {
  if (typeof data !== 'undefined') {
    return data?.clinicalStatus?.coding?.[0]?.display;
  }
  return true;
}

export function getAllergyExtension(data) {
  if (data?.extension) {
    return data?.extension?.find(item => item?.url === 'TYPE')?.valueString;
  } else return '';
}

export function getEncounterExtensionValue(
  extensions: fhir.Extension[],
  url: fhir.uri = PATIENT_CHECKED_IN_URL,
  valueType: string = 'valueDateTime'
) {
  if (Array.isArray(extensions) && extensions.length > 0) {
    return extensions.find(item => item.url === url)?.[valueType];
  }
  return null;
}

export const deepCopyObject = srcObject =>
  JSON.parse(JSON.stringify(srcObject));

export const debounce = (callback, delay) => {
  let timer;
  return {
    function: function(...args) {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay);
    },
    cancel: () => {
      clearTimeout(timer);
    },
  };
};

export const detectDevice = () => {
  let deviceOS = 'unknownos';
  if (navigator?.appVersion.indexOf('Win') !== -1) deviceOS = 'pc';
  if (navigator?.appVersion.indexOf('Mac') !== -1) deviceOS = 'tablet';
  if (navigator?.appVersion.indexOf('Android') !== -1) deviceOS = 'tablet';
  if (deviceOS === 'tablet' && navigator?.maxTouchPoints < 2) deviceOS = 'pc';
  return deviceOS;
};

export function removeReferenceResource(value) {
  return JSON.parse(JSON.stringify(value), (key, value) => {
    if (key === 'resource') {
      return undefined;
    }

    return value;
  });
}

export const findIndex = (array, condition) => {
  let refillIndex = -1;
  for (let index = 0; index < array.length; index++) {
    if (condition(array[index])) {
      refillIndex = index;
      break;
    }
  }
  return refillIndex;
};
export const flattenedCategoryCodeableConept = (items, system) => {
  const modifiedObject = items?.CodeSystem?.concept?.map(category => {
    return {
      code: category?.code,
      display: category?.display,
      concept: category?.concept?.map(item => {
        return {
          coding: [
            {
              code: item.code,
              display: item.display,
              system: system,
            },
          ],
          text: '',
        };
      }),
    };
  });
  return modifiedObject?.flat();
};

export function getCodeableConceptCode(
  concept?: fhir.CodeableConcept
): string | undefined {
  return concept?.coding?.[0]?.code;
}

export function getCodeableConceptDisplay(
  concept?: fhir.CodeableConcept
): string | undefined {
  return concept?.coding?.[0]?.display;
}

export function sortCodeableConceptDisplay(
  conceptArr: fhir.CodeableConcept[],
  sortAscending: boolean
): fhir.CodeableConcept[] {
  return conceptArr?.sort((option1, option2) => {
    const option1Display = getCodeableConceptDisplay(option1) || '';
    const option2Display = getCodeableConceptDisplay(option2) || '';
    const isGreater = option1Display > option2Display ? 1 : -1;
    return sortAscending ? isGreater : isGreater * -1;
  });
}

export function getCodeableConceptSystem(
  concept?: fhir.CodeableConcept
): string | undefined {
  return concept?.coding?.[0]?.system;
}

export function getTimezoneNameByState(state: string) {
  return (
    {
      AZ: 'America/Phoenix',
      CA: 'America/Los_Angeles',
      FL: 'America/New_York',
      NJ: 'America/New_York',
      NV: 'America/Los_Angeles',
      OH: 'America/New_York',
      PR: 'America/Puerto_Rico',
      TX: 'America/Chicago',
      UT: 'America/Denver',
      HI: 'Pacific/Honolulu',
    }[state] ?? 'America/New_York' // fallback to 'America/New_York'
  );
}
