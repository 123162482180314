import React, {
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useQuery, DocumentNode } from '@apollo/client';
import DataList from 'styleguide/DataList';
import { DEFAULT_PAGE_SIZE, DEFAULT_RECORD_INDEX } from 'utility/constants';
import {
  extractSelectedAppointments,
  getNoPcpFax,
  extractSelectedAppointmentIds,
  hasPcpFax,
  sortArray,
} from 'common/utils';
import {
  CURRENT_MEDICAL_CONDITION,
  CMC_HISTORY_OF_PRESENT_ILLNESS_TITLE,
} from 'currentmedicalcondition/constants';
import { MADE_ENCOUNTERS_PRIMARY_LIST_TYPE } from 'bulk-operations/constants';

export type Props = {
  fetchPolicy?:
    | 'network-only'
    | 'cache-first'
    | 'cache-only'
    | 'no-cache'
    | 'standby'
    | 'cache-and-network'
    | undefined;
  type: string;
  subType?: string;
  query: DocumentNode;
  variables: any;
  dataListProps: any;
  sortResults?: (data: any) => void;
  filterResults?: (data: any) => void;
  paginationType?: 'fhir' | 'maxlimit' | 'valueset' | 'response' | 'elastic';
  subscribedTo?: DocumentNode[];
  skip?: boolean;
  dataListItemProps?: any;
  title?: string;
  displayMessage?: string;
  fullRow?: boolean;
  getTotalCount?: (count: number) => void;
  refetchPrimary?: boolean;
  getCurrentPageTotal?: (count: number) => void;
  autoScrollToActive?: boolean;
};

const PrimaryList: React.FC<Props> = ({
  fetchPolicy = 'network-only',
  type,
  subType,
  query,
  variables,
  dataListProps,
  sortResults,
  filterResults,
  subscribedTo = undefined,
  paginationType = 'fhir',
  skip,
  dataListItemProps,
  title,
  displayMessage,
  fullRow,
  getTotalCount,
  refetchPrimary = false,
  getCurrentPageTotal = () => {},
  autoScrollToActive = false,
}) => {
  const {
    subscribeToMore,
    data,
    error,
    loading,
    fetchMore,
    networkStatus,
    refetch,
  } = useQuery(query, {
    skip,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    variables:
      paginationType === 'elastic'
        ? {
            first: DEFAULT_PAGE_SIZE,
            after: DEFAULT_RECORD_INDEX,
            ...variables,
          }
        : paginationType === 'fhir'
        ? { first: DEFAULT_PAGE_SIZE, ...variables }
        : { ...variables },
  });
  const {
    showText,
    onCheckBoxSelect,
    listType,
    showMultipleSelect,
    showAccordion,
    selectedIds,
    removeSelectedId,
    allRecordsSelected,
    onLinkButtonClick,
  } = dataListProps;
  const [loadOptions, setLoadOptions] = useState('');

  useEffect(() => {
    const subscribtions: Function[] = [];

    if (Array.isArray(subscribedTo) && subscribedTo.length > 0) {
      subscribedTo.forEach(document => {
        const subscribtion = subscribeToMore({
          document,
          variables,
          updateQuery: (prev, { subscriptionData }) => {
            const currentPageCount =
              prev?.[type]?.edges?.length || DEFAULT_PAGE_SIZE;
            let subscriptionVariables = {
              ...variables,
              first: currentPageCount,
            };
            fetchMore({
              variables: subscriptionVariables,
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return fetchMoreResult;
              },
            });
          },
        });

        subscribtions.push(subscribtion);
      });
    }
    if (showText || showMultipleSelect) {
      setLoadOptions(loadOptions === '' ? 'loadMore' : '');
    }
    return () => {
      subscribtions.forEach(unsubscribe => unsubscribe());
    };
    // eslint-disable-next-line
  }, [fetchMore, subscribeToMore, subscribedTo, variables]);

  const handleLoadMore = useCallback(() => {
    switch (paginationType) {
      case 'fhir': {
        const items = data?.[type]?.edges || [];
        fetchMore({
          variables: {
            after: items[items.length - 1].cursor,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            getCurrentPageTotal(
              items.length + (fetchMoreResult?.[type]?.edges || []).length
            );
            return Object.assign({}, prev, {
              [type]: {
                ...(prev?.[type] || {}),
                edges: [
                  ...(prev?.[type]?.edges || []),
                  ...(fetchMoreResult?.[type]?.edges || []),
                ],
              },
            });
          },
        });
        break;
      }
      case 'elastic': {
        const items = data?.[type]?.edges || [];
        fetchMore({
          variables: {
            after: items.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            getCurrentPageTotal(
              items.length + (fetchMoreResult?.[type]?.edges || []).length
            );
            return Object.assign({}, prev, {
              [type]: {
                ...(prev?.[type] || {}),
                edges: [
                  ...(prev?.[type]?.edges || []),
                  ...(fetchMoreResult?.[type]?.edges || []),
                ],
              },
            });
          },
        });
        break;
      }
      case 'maxlimit': {
        variables.maxlimit = (
          parseInt(variables.maxlimit ? variables.maxlimit : 0) +
          DEFAULT_PAGE_SIZE
        ).toString();
        fetchMore({
          variables: variables,
        });
        break;
      }
      case 'valueset': {
        fetchMore({
          variables: {
            pageNumber: (
              parseInt(data?.[type]?.expansion?.currentPage) + 1
            ).toString(),
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              [type]: {
                ...prev?.[type],
                expansion: {
                  ...prev?.[type].expansion,
                  contains: [
                    ...prev?.[type].expansion?.contains,
                    ...fetchMoreResult?.[type].expansion?.contains,
                  ],
                  currentPage: fetchMoreResult?.[type].expansion?.currentPage,
                },
              },
            });
          },
        });
        break;
      }
      case 'response': {
        fetchMore({
          variables: {
            pageParams: {
              currentPage: data?.[type]?.currentPage + 1,
              recordsPerPage:
                variables?.pageParams?.recordsPerPage || DEFAULT_PAGE_SIZE,
            },
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return Object.assign({}, prev, {
              [type]: {
                ...(prev?.[type] || {}),
                response: [
                  ...(prev?.[type]?.response || []),
                  ...(fetchMoreResult?.[type]?.response || []),
                ],
                currentPage: fetchMoreResult?.[type]?.currentPage,
              },
            });
          },
        });
        break;
      }
    }
  }, [data, fetchMore, paginationType, type, variables, getCurrentPageTotal]);

  const results = useMemo(() => {
    if (data?.[type]?.edges) {
      return (data?.[type]?.edges || []).map((item: any) => item?.node);
    }
    if (data?.[type]?.response) {
      return data?.[type]?.response;
    }
    if (data?.[type]?.expansion?.contains) {
      const resultData = data?.[type]?.expansion?.contains?.map(response => {
        if (response.code === CURRENT_MEDICAL_CONDITION) {
          const cmcData = {
            ...response,
            display: CMC_HISTORY_OF_PRESENT_ILLNESS_TITLE,
          };
          return cmcData;
        }
        return response;
      });

      return resultData;
    }
    if (subType && data?.[type]?.[subType]) {
      return data?.[type]?.[subType];
    }
    if (data?.[type]?.concept) {
      return data?.[type]?.concept.length ? data?.[type]?.concept : [];
    }
    return data?.[type] || [];
  }, [data, type, subType]);

  const total: number = useMemo(
    () =>
      Number(
        data?.[type]?.totalRecords ||
          data?.[type]?.totalCount ||
          data?.[type]?.total ||
          data?.[type]?.expansion?.totalCount ||
          0
      ),
    [data, type]
  );

  useEffect(() => {
    if (refetchPrimary) {
      refetch();
    }
    getTotalCount && getTotalCount(total);
  }, [getTotalCount, total, refetchPrimary, refetch]);

  const filteredResults = useMemo(() => {
    return filterResults ? filterResults(results) : results;
  }, [results, filterResults]);

  const sortedResults = useMemo(() => {
    const results = sortResults
      ? sortResults(filteredResults)
      : sortArray({ item: [...filteredResults] }, 'display');
    return results?.item ? results?.item : results;
  }, [filteredResults, sortResults]);

  const reducerSelectAll = (state, action) => {
    switch (action.type) {
      case 'toggleSelectAll':
        return !state;
      case 'resetSelectAll':
        return false;
      case 'cardCheckedChanged':
        const allChecked = action.sortedResultWithCheckBoxOption
          ?.filter(item => item?.pcpFax === true)
          ?.every(item => item?.isChecked);
        return showMultipleSelect ? false : allChecked;
      default:
        return state;
    }
  };
  const [selectAllChecked, dispatchSelectAllStates] = useReducer(
    reducerSelectAll,
    false
  );

  const initSortedResultWithCheckBoxOption = useMemo(() => {
    return sortedResults.map(item => {
      return {
        ...item,
        isChecked: showMultipleSelect
          ? selectedIds?.indexOf(item?.id) >= 0
          : false,
        pcpFax: hasPcpFax(listType, item),
      };
    });
  }, [sortedResults, listType, selectedIds, showMultipleSelect]);
  const reducer = (state, action) => {
    let newVal: any;
    let selectedValues: any;
    switch (action.type) {
      case 'reload':
        return action.value;
      case 'reset':
        newVal = action.value.map(item => {
          return {
            ...item,
            isChecked: false,
            pcpFax: hasPcpFax(listType, item),
          };
        });
        return newVal;
      case 'selectAllCheckBox':
        newVal = action.value.map(item => {
          return { ...item, isChecked: item.pcpFax && action.selectAllStatus };
        });
        selectedValues = extractSelectedAppointments(newVal);
        onCheckBoxSelect(selectedValues);
        return newVal;
      case 'cardCheckbox':
        newVal = action.value.map(item =>
          item.id === action.targetItemId
            ? { ...item, isChecked: action.cardCheckBoxStatus }
            : item
        );
        dispatchSelectAllStates({
          type: 'cardCheckedChanged',
          sortedResultWithCheckBoxOption: newVal,
        });
        selectedValues = extractSelectedAppointments(newVal);
        const uncheckedIds = !action.cardCheckBoxStatus
          ? action.targetItemId
          : '';
        onCheckBoxSelect(selectedValues, uncheckedIds);
        return newVal;
      case 'loadMore':
        const checkedAppointmentsIds = extractSelectedAppointmentIds(state);
        newVal = action.value.map(item => {
          if (
            checkedAppointmentsIds.length > 0 &&
            checkedAppointmentsIds.includes(item.id)
          ) {
            return {
              ...item,
              isChecked: checkedAppointmentsIds.includes(item.id),
            };
          } else {
            return {
              ...item,
              isChecked: item.pcpFax && action.selectAllStatus,
            };
          }
        });
        return newVal;
      default:
        return state;
    }
  };

  const [sortedResultWithCheckBoxOption, dispatch] = useReducer(
    reducer,
    initSortedResultWithCheckBoxOption
  );

  useEffect(() => {
    if ((showText || showMultipleSelect) && loadOptions === '') {
      dispatch({ type: 'reset', value: sortedResults });
      dispatchSelectAllStates({ type: 'resetSelectAll' });
      onLinkButtonClick && onLinkButtonClick(false);
    }
    if ((showText || showMultipleSelect) && loadOptions === 'loadMore') {
      dispatch({
        type: 'loadMore',
        value: initSortedResultWithCheckBoxOption,
        selectAllStatus: selectAllChecked,
      });
    }
    // eslint-disable-next-line
  }, [initSortedResultWithCheckBoxOption, loadOptions]);

  const onToggleSelectAll = useCallback(() => {
    dispatchSelectAllStates({ type: 'toggleSelectAll' });
    dispatch({
      type: 'selectAllCheckBox',
      value: sortedResultWithCheckBoxOption,
      selectAllStatus: !selectAllChecked,
    });
  }, [selectAllChecked, sortedResultWithCheckBoxOption]);

  const onCardCheckBoxToggle = useCallback(
    ({ target }) => {
      if (selectedIds?.indexOf(target?.id) >= 0) {
        removeSelectedId(target?.id);
      }
      dispatch({
        type: 'cardCheckbox',
        value: sortedResultWithCheckBoxOption,
        cardCheckBoxStatus: target?.checked,
        targetItemId: target?.id,
      });
      if (!target?.checked) {
        onLinkButtonClick && onLinkButtonClick(false);
      }
    },
    [
      sortedResultWithCheckBoxOption,
      removeSelectedId,
      selectedIds,
      onLinkButtonClick,
    ]
  );

  const HandleLinkClickSelectAll = useCallback(() => {
    if (selectAllChecked && !allRecordsSelected) {
      return onLinkButtonClick && onLinkButtonClick(true);
    }
    onToggleSelectAll();
    onLinkButtonClick && onLinkButtonClick(!selectAllChecked);
  }, [
    onLinkButtonClick,
    selectAllChecked,
    allRecordsSelected,
    onToggleSelectAll,
  ]);

  const [noPcpFax, setNoPcpFax] = useState(false);
  useEffect(() => {
    if (showText) {
      setNoPcpFax(!getNoPcpFax(sortedResultWithCheckBoxOption));
    }
  }, [showText, sortedResultWithCheckBoxOption]);

  const selectedCountSortedResult = useMemo(
    () =>
      sortedResultWithCheckBoxOption?.filter?.(item => item?.isChecked === true)
        ?.length,
    [sortedResultWithCheckBoxOption]
  );

  useEffect(() => {
    if (
      showMultipleSelect &&
      selectedCountSortedResult <= selectedIds?.length
    ) {
      dispatch({
        type: 'reload',
        value: initSortedResultWithCheckBoxOption,
      });
    }
  }, [
    selectedIds,
    selectedCountSortedResult,
    showMultipleSelect,
    initSortedResultWithCheckBoxOption,
  ]);

  return (
    <DataList
      type={type}
      fullRow={fullRow}
      title={title}
      data={
        showText || showMultipleSelect
          ? sortedResultWithCheckBoxOption
          : sortedResults
      }
      error={error?.message || ''}
      loading={loading}
      networkStatus={networkStatus}
      onLoadMore={handleLoadMore}
      total={total}
      selectedCount={dataListProps?.selectionCount}
      showItemCard={paginationType !== 'maxlimit'}
      itemProps={dataListItemProps}
      displayMessage={displayMessage}
      {...dataListProps}
      onCreate={
        dataListProps?.onCreate ? () => dataListProps?.onCreate?.() : undefined
      }
      onToggleSelectAll={onToggleSelectAll}
      selectAllChecked={selectAllChecked}
      onCardCheckBoxToggle={onCardCheckBoxToggle}
      noPcpFax={noPcpFax}
      allRecordsSelected={allRecordsSelected}
      onLinkButtonClick={HandleLinkClickSelectAll}
      showAccordion={showAccordion}
      showSummary={type === MADE_ENCOUNTERS_PRIMARY_LIST_TYPE ? false : true}
      autoScrollToActive={autoScrollToActive}
    />
  );
};

export default React.memo(PrimaryList);
