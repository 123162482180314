import React from 'react';
import Card from 'styleguide/layout/Card';

export type Props = {
  active?: boolean;
  component: React.FC<any>;
  data: { [key: string]: any };
  disabled: boolean;
  showItemCard?: boolean;
  dataProps?: any;
  dataTestId?: string;
};

const DataListItem: React.FC<Props> = ({
  active,
  component: Component,
  data,
  disabled = false,
  showItemCard,
  dataProps,
  dataTestId,
}) => {
  if (showItemCard) {
    return (
      <Card disabled={disabled} active={active}>
        <Component data={data} dataProps={dataProps} dataTestId={dataTestId} />
      </Card>
    );
  } else {
    return (
      <Component
        data={data}
        disabled={disabled}
        active={active}
        dataProps={dataProps}
        dataTestId={dataTestId}
      />
    );
  }
};

export default React.memo(DataListItem);
